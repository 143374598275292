.header {
    display: flex;
    justify-content: space-between;
}
.logo {
    width: 10rem;
    height: 3rem;
}
.list-menu {
    list-style: none;
    color: white;
    display: flex;
    gap: 2rem;
}

.list-menu > li:hover {
    cursor: pointer;
    color: var(--orange);
}

@media screen and (max-width:768px) {
    .header>:nth-child(2) {
        position:fixed;
        right: 2rem;
        z-index: 99;
    }
    .list-menu {
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
        border-radius: 6px;
   
    }
}