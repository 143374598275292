.plans {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: -4rem;
    padding: 0 2rem;
    gap:4rem;
}
.plans-blur-1 {
    width: 32rem;
    height: 23rem;
    left: 0;
    top: 6rem;
}
.plans-blur-2 {
    width: 32rem;
    height: 23rem;
    right: 0;
    top: 10rem;
}
.plans .plans-head {
    display: flex;
    justify-content: center;
    gap: 2rem;
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    font-style: italic;
}
.plans .plans-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.plans-body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--caloryCard);
    color: white;
    width: 15rem;
    padding: 1.5rem;
}

.plans-body:nth-child(2) {
    background: var(--planCard);
    transform: scale(1.1);
}
.plans-body>svg {
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plans-body>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.plans-body>:nth-child(3){
font-size: 3rem;
font-weight: bold;
}
.plans-body>:nth-child(5){
    font-size: 0.8rem;
}

.features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.features .feat {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.features .feat>img {
    width: 1rem;
}

.plans-body:nth-child(2)>svg {
    fill: white;
}
.plans-container>:nth-child(2)>button {
    color: var(--orange);
}

@media screen and (max-width:768px) {
    .plans-head {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 40px;
    }
    .plans-head span {
        font-size: 25px;
    }
    
    .plans-container {
        flex-direction: column;
    }
}