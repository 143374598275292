.programes {
    display: flex;
    gap: 3rem;
    flex-direction: column;
}
.programes .header-programes {
    display: flex;
    gap: 5rem;
    justify-content: center;
    text-transform: uppercase;
    font-size: 3rem;
    color: white;
    font-weight: bold;
    font-style: italic;


}

.programe-catagories {
    display: flex;
    gap: 1rem;
    justify-content: center;
}
.catagore {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: gray;
    padding: 2rem;
    color: white;
    justify-content: space-between;
    width: 18%;
}
.catagore>:nth-child(1) {
    width: 2rem;
    height: 2rem;
    fill: white;
}

.catagore>:nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
}

.catagore>:nth-child(3) {
    font-size: 0.9rem;
    line-height: 25px;
}

.join-now {
    display: flex;
    gap: 2rem;
}
.join-now>img {
    width: 1rem;
}

.catagore:hover {
    background: var(--planCard);
    cursor: pointer;
}
@media screen and (max-width:768px)  {
    .header-programes {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        margin-top: 3rem;
    }
    .header-programes span {
        font-size: xx-large;
    }
    .programe-catagories {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .programe-catagories > div {
        width: 60%;
        text-align: center;
    }
    
}